@charset "UTF-8";
// sass:meta allows the @use file with (variable) pattern
@use 'sass:meta';

// Base styles
@use '~rucksack/scss/base/reset';
@use '~rucksack/scss/base/typography';
@use '~rucksack/scss/base/buttons';
@use '~rucksack/scss/base/inputs';

// Molecules
@use '~rucksack/scss/forms/archive_selector' with (
  $archive-selector-button-bg: #35383e,
  $archive-selector-button-hover-bg: #CED1D7
);
@use '~rucksack/scss/forms/serach_form';
@use '~rucksack/scss/forms/comment-section';

// Patterns / Organisms
@use '~rucksack/scss/layout/ME-header';
@use '~rucksack/scss/patterns/article-block';
@use '~rucksack/scss/patterns/article-listing';
@use '~rucksack/scss/patterns/article-rating';
@use '~rucksack/scss/patterns/golden-question';
@use '~rucksack/scss/patterns/lead-story' with (
  $lead-story-bg: #fcfbed,
  $lead-story-text: #000, 
  $lead-story-keyword: #c40000,
  $lead-story-date: #6c6b6b
);
@use '~rucksack/scss/patterns/latest_news';
@use '~rucksack/scss/patterns/most-popular-articles';
@use '~rucksack/scss/patterns/newsletter-sidebar' with (
  $social-bg: #35383e,
  $social-text: #fff,
  $social-button: #c40000,
  $social-border: #c40000,
  $social-button-text: #fff,
);
@use '~rucksack/scss/patterns/pagination';
@use '~rucksack/scss/patterns/poll' with (
  $poll-heading: #000,
  $poll-question: #000,
  $poll-button-background: #c40000,
  $poll-button-text: #fff,
  $spotlight-poll-border-color: #000,
  $poll-progress: #c40000,
  $poll-bg-color: #e8e7e7
);
@use '~rucksack/scss/patterns/slideshow';
@use '~rucksack/scss/patterns/spotlight' with (
  $spotlight-bg: #e2f3f8,
);
@use '~rucksack/scss/forms/user_form' with (
  $feedback-btn-bg-color: #e30000,
  $comment-btn-bg-color: #333333,
  $comment-btn-txt-color: #fff,
  $comment-form-heading-bg: #333333,
  $comment-form-heading-txt-color: #fff
);

// Layout-related section / Templates
@use '~rucksack/scss/layout/layout';
@use '~rucksack/scss/layout/footer' with (
  $footer-background: #e30000,
  $footer-link-selected: #fff
);

// Page Specific Styles
@use '~rucksack/scss/pages/archive-page';
@use '~rucksack/scss/pages/article-page' with (
  $article-title-color: #000,
  $article-teaser-color: #000,
  $article-date-color: #6c6b6b,
  $article-section-title-color: #35383e,
  $article-keyword-color: #c40000,
  $related-head-bg-color: #cb000d,
  $related-head-text-color: #fff,
  $latest-listings-heading-txt-color: #c40000

);
@use '~rucksack/scss/pages/keyword-page' with (
  $keyword-page-title:#c40000,
  $keyword-title-bottom-border: 1px solid #35383e
);
@use '~rucksack/scss/pages/home-page' with (
  $social-bg: #35383e,
  $social-text: #fff,
  $social-button: #c40000,
  $social-border: #c40000,
  $social-button-text: #fff,
  $newsletter-text-color: #fff
);
@use '~rucksack/scss/pages/search-page' with (
  $search-term: #ea1502,
  $sort-search-radio-active: #ea1502
);
@use '~rucksack/scss/pages/newsletter-page' with (
  $newsletter-success-bg: #191b19,
  $newsletter-success-txt: #fff,
  $newsletter-line-break: #c40000,
  $newsletter-sample-border: #191b19,
  $newsletter-page-btn-bg: #191b19,
  $newsletter-page-btn-color: #fff,
  $newsletter-page-social-border: #c40000,
  $telegram-promo-link: #1b75bb,
  $sample-heading-bg-color: #fff,
  $sample-newsletter-bg: #f1f1f2,
  $sample-heading-logo-border: 5px solid #c40000,
  $article-title: #000
);

.header__locale-link--selected {
  color: #fff !important;
}

.search-form {
  border-color: #d3d3d3;

  .search-form__button {
    height: 44px;
  }
}

.social--index .newsletter__form__wrapper--sidebar button {
  color: #fff;
  font-weight: 500;
}

.spotlight .article__title {
  font-size: 24px;
}

.spotlight-poll .spotlight__img {
  margin: 8px 0;
}

.slideshow__button {
  background-color: #e8e7e7;
}

.articlce__source-row {
  display: inline-block;

  .article__date {
    margin: 8px 0;
  }
}

@supports (justify-content: space-between) {
  .article__source-row {
    display: inline-flex;
    width: 100%;
    padding: 0 8px;

    .article__date {
      margin: 0;
      align-self: flex-end;
    }
  }
}

.article__sharing-icons {
  margin: auto;
  margin: initial;
  margin: unset;
}

section.article__sharing-icons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #333;
  padding: 12px;
  margin: 20px 0;
  border-radius: 4px;

  @media all and (min-width: 450px) {
    flex-direction: row;
  }

  .share-this-article {
    align-self: center;
    color: #fff;

    html[dir='rtl'] & {
      margin-right: 16px;
    }

    html[dir='ltr'] & {
      margin-left: 16px;
    }
  }

  ul {
    @media all and (max-width: 449px) {
      text-align: center;
    }

    list-style: none;

    li {
      display: inline-block;
    }
  }

  .shareAPI {
    height: unset;
    width: unset;
    margin-bottom: 0;
    padding-top: 4px;
    height: 36px;

    svg,
    img {
      padding: 0;
      height: 36px;
      width: 36px;
    }
  }
}
.layout--archive {
  @media all and (min-width: 900px) {
    .article {
      flex-basis: unset;
      flex-basis: auto;
    }
    .latest-news {
      flex-basis: 55%;
    }
  }

  ol {
    list-style-type: none;

    li {
      list-style-type: none;
    }
  }
}

.layout__keyword-page {
  list-style: none;

  li {
    list-style-type: none;
  }

  .article--block__text .article__keyword {
    display: none;
  }
}

.social-channels__icon-wrapper {
  list-style: none;

  li {
    list-style: none;
    display: inline-block;
  }
}

.article--search .article--listing .article__title {
  font-size: 20px;
}

html[dir='ltr'] .search-form .search-form__button img,
html[dir='ltr'] .search-form .search-form__button svg,
html[dir='rtl'] .search-form .search-form__button img,
html[dir='rtl'] .search-form .search-form__button svg {
  float: none;
}

.related-articles-section ol {
  list-style: none;
}

.layout--index__top-row {
  list-style-type: none;
}

.article__teaser .article__teaser--article-page {
  font-size: 18px;
}

.article--block .article__title {
  font-size: 20px;
}

.most-popular ol {
  list-style: none;

  li {
    list-style: none;
  }
}

.layout--index__features .article--block {
  list-style: none;
}

.article__teaser.article__teaser--article-page {
  font-weight: 400;
  font-weight: 24px;
}

.article--listing .article__title {
  font-size: 20px;
  font-weight: 500;
}

.archive-selector__title {
  font-size: 24px;
}

.sort-search .sort-search__radio--active + label {
  color: #ea1502;
  text-decoration: none;
}

.article__list {
  html[dir='ltr'] & {
    padding: 0 0 0 32px;
  }

  html[dir='rtl'] & {
    padding: 0 32px 0 0;
  }
}

.article--listing--inline {
  list-style: none;
}

@media all and (max-width: 475px) {
  .user-form .user-form__policy-link {
    float: none;
    display: block;
  }

  html[dir='rtl'] .user-form .user-form__policy-link {
    float: none;
    display: block;
  }

  .user-form__label--comment {
    display: block;
  }
}

.poll-page__container {
  background-color: #e8e7e7;

  section.poll {
    padding: 12px;
    margin: auto auto 16px;
    display: block;
    max-width: 500px;

    .js-poll {
      max-width: 500px;
      display: block;
      margin: auto;
    }

    .poll__question {
      max-width: 500px;
      margin: auto;
    }
  }
}

aside.polls {
  display: none;
}
